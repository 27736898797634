import pathsUS from '../../locales/paths/us.json';
import pathsCO from '../../locales/paths/co.json';
import pathsIR from '../../locales/paths/ir.json';
import pathsMX from '../../locales/paths/mx.json';
import pathsUK from '../../locales/paths/uk.json';
import pathsIN from '../../locales/paths/in.json';
import pathsVN from '../../locales/paths/vn.json';

const sources = {
  us: pathsUS,
  co: pathsCO,
  ir: pathsIR,
  mx: pathsMX,
  uk: pathsUK,
  in: pathsIN,
  vn: pathsVN,
};

// Helper object to lookup translated paths based on the current URL
// Since the translation system only loads the *current* locale data
// This one file holds data for all paths in *all* locales regardless of
// which build is run.
const pathMap = {};

Object.keys(sources).forEach((sourceLocale) => {
  // Lets iterate through a locale
  const currentSource = sources[sourceLocale];
  // We will check other locales for matching paths
  const otherLocales = Object.keys(sources).filter(
    (otherLocale) => otherLocale !== sourceLocale
  );
  // Now iterate through the current source's paths
  Object.keys(currentSource).forEach((key) => {
    // Only process keys that start with '/', which
    // we assume means this is a path id
    // Also only process if the current entry has a 'path' property.
    if (key.startsWith('/') && currentSource[key].path) {
      otherLocales.forEach((otherLocale) => {
        const otherSource = sources[otherLocale];
        // If another locale has the same key, add it the result map!
        if (otherSource[key] && otherSource[key].path) {
          // Instantiate with object if it didn't exist
          if (!pathMap[otherSource[key].path]) {
            pathMap[otherSource[key].path] = {};
          }
          // Finally add the map entry
          pathMap[otherSource[key].path][sourceLocale] =
            currentSource[key].path;
        }
      });
    }
  });
});

export default pathMap;
