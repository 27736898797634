import React from 'react';
import OutboundLink from '../OutboundLink';
import { TextInput, Checkbox, Select } from '../Form';
import roles from './roles';
import { months, days, years } from './birthday';

/*=============================================
=            Schema US            =
=============================================*/

const schemaUS = {
  email: {
    label: 'Email',
    component: TextInput,
    type: 'email',
    value: '',
    placeholder: 'email@address.com',
    required: true,
    validation: {
      test: (value) => /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(value),
      message: 'Please provide a valid email address.',
    },
  },
  inGradesKto3: {
    label: 'K–3',
    component: Checkbox,
    checked: false,
  },
  inGrades4to5: {
    label: '4–5',
    component: Checkbox,
    checked: false,
  },
  inGrades6to8: {
    label: '6–8',
    component: Checkbox,
    checked: false,
  },
  inGrades9to12: {
    label: '9–12',
    component: Checkbox,
    checked: false,
  },
  interestedInEducation: {
    label: 'Health and Physical Education',
    component: Checkbox,
    checked: false,
  },
  interestedInSTEM: {
    label: 'STEM',
    component: Checkbox,
    checked: false,
  },
  interestedInLanguageArts: {
    label: 'Language Arts',
    component: Checkbox,
    checked: false,
  },

  interestedInVirtualFieldTrips: {
    label: 'Virtual Field Trips',
    component: Checkbox,
    checked: false,
  },
  interestedInSweepstakesAndChallenges: {
    label: 'Sweepstakes and Challenges',
    component: Checkbox,
    checked: false,
  },
  role: {
    label: 'Role',
    component: Select,
    placeholder: 'Select',
    options: roles,
    value: '',
    required: false,
    labelHidden: false,
  },
  month: {
    label: 'Month',
    component: Select,
    placeholder: 'Month',
    options: months,
    value: '',
    required: true,
    labelHidden: true,
  },
  year: {
    label: 'Year',
    component: Select,
    placeholder: 'Year',
    options: years,
    value: '',
    required: true,
    labelHidden: true,
  },
  subscribeToDE: {
    label: (
      <>
        I would like to stay up-to-date with service enhancements, news content,
        professional development opportunities, special events, education
        programs and news from Discovery Education [and its subsidiaries] in
        accordance with Discovery Education’s{' '}
        <a
          href="https://www.discoveryeducation.com/privacy-policy"
          target="_blank"
          rel="noopener noreferrer"
        >
          Privacy Policy
        </a>
        .
      </>
    ),
    component: Checkbox,
    checked: false,
  },
  subscribeToPartner: {
    label: (programName, partnerPrivacyUrl) => (
      <>
        I would like to receive information from the Abbott Fund [and Abbott
        ______] about {programName} program as well as other Abbott Fund
        initiatives in accordance with Abbott Fund’s{' '}
        <OutboundLink to={partnerPrivacyUrl}>Privacy Policy</OutboundLink>.
      </>
    ),
    component: Checkbox,
    checked: false,
  },
};

/*=============================================
=            Schema IR            =
=============================================*/

const schemaIR = {
  email: {
    label: 'Email',
    component: TextInput,
    type: 'email',
    value: '',
    placeholder: 'email@address.com',
    required: true,
    validation: {
      test: (value) => /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(value),
      message: 'Please provide a valid email address.',
    },
  },
  inGradesKto3: {
    label: 'K–3',
    component: Checkbox,
    checked: false,
  },
  inGrades4to5: {
    label: '4–5',
    component: Checkbox,
    checked: false,
  },
  inGrades6to8: {
    label: '6–8',
    component: Checkbox,
    checked: false,
  },
  inGrades9to12: {
    label: '9–12',
    component: Checkbox,
    checked: false,
  },
  interestedInEducation: {
    label: 'Health and Physical Education',
    component: Checkbox,
    checked: false,
  },
  interestedInSTEM: {
    label: 'STEM',
    component: Checkbox,
    checked: false,
  },
  interestedInLanguageArts: {
    label: 'Language Arts',
    component: Checkbox,
    checked: false,
  },

  interestedInVirtualFieldTrips: {
    label: 'Virtual Field Trips',
    component: Checkbox,
    checked: false,
  },
  interestedInSweepstakesAndChallenges: {
    label: 'Competitions and Challenges',
    component: Checkbox,
    checked: false,
  },
  role: {
    label: 'Role',
    component: Select,
    placeholder: 'Select',
    options: roles,
    value: '',
    required: false,
    labelHidden: false,
  },
  month: {
    label: 'Month',
    component: Select,
    placeholder: 'Month',
    options: months,
    value: '',
    required: true,
    labelHidden: true,
  },
  year: {
    label: 'Year',
    component: Select,
    placeholder: 'Year',
    options: years,
    value: '',
    required: true,
    labelHidden: true,
  },
  subscribeToDE: {
    label: (
      <>
        I would like to stay up-to-date with service enhancements, news content,
        professional development opportunities, special events, education
        programmes and news from Discovery Education [and its subsidiaries] in
        accordance with Discovery Education’s{' '}
        <a
          href="https://www.discoveryeducation.co.uk/privacy-policy/"
          target="_blank"
          rel="noopener noreferrer"
        >
          Privacy Policy
        </a>
        .
      </>
    ),
    component: Checkbox,
    checked: false,
  },
  subscribeToPartner: {
    label: (programName, partnerPrivacyUrl) => (
      <>
        I would like to receive information from the Abbott Fund [and Abbott
        ______] about {programName} programme, as well as other Abbott Fund
        initiatives in accordance with Abbott Fund’s{' '}
        <OutboundLink to={partnerPrivacyUrl}>Privacy Policy</OutboundLink>.
      </>
    ),
    component: Checkbox,
    checked: false,
  },
};

/*=============================================
=            Schema UK            =
=============================================*/

const schemaUK = {
  email: {
    label: 'Email',
    component: TextInput,
    type: 'email',
    value: '',
    placeholder: 'email@address.com',
    required: true,
    validation: {
      test: (value) => /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(value),
      message: 'Please provide a valid email address.',
    },
  },
  inGradesKto3: {
    label: 'Year 4',
    component: Checkbox,
    checked: false,
  },
  inGrades4to5: {
    label: 'Years 5-6',
    component: Checkbox,
    checked: false,
  },
  inGrades6to8: {
    label: 'Years 7-9',
    component: Checkbox,
    checked: false,
  },
  inGrades9to12: {
    label: 'Years 10-13',
    component: Checkbox,
    checked: false,
  },
  interestedInEducation: {
    label: 'Physical, Social, Health & Economic Education',
    component: Checkbox,
    checked: false,
  },
  interestedInSTEM: {
    label: 'STEM',
    component: Checkbox,
    checked: false,
  },
  interestedInLanguageArts: {
    label: 'Language Arts',
    component: Checkbox,
    checked: false,
  },

  interestedInVirtualFieldTrips: {
    label: 'Virtual Field Trips',
    component: Checkbox,
    checked: false,
  },
  interestedInSweepstakesAndChallenges: {
    label: 'Games and Challenges',
    component: Checkbox,
    checked: false,
  },
  role: {
    label: 'Role',
    component: Select,
    placeholder: 'Select',
    options: roles,
    value: '',
    required: false,
    labelHidden: false,
  },
  month: {
    label: 'Month',
    component: Select,
    placeholder: 'Month',
    options: months,
    value: '',
    required: true,
    labelHidden: true,
  },
  year: {
    label: 'Year',
    component: Select,
    placeholder: 'Year',
    options: years,
    value: '',
    required: true,
    labelHidden: true,
  },
  subscribeToDE: {
    label: (
      <>
        I would like to stay up-to-date with service enhancements, news content,
        professional development opportunities, special events, education
        programmes and news from Discovery Education [and its subsidiaries] in
        accordance with Discovery Education’s{' '}
        <a
          href="https://www.discoveryeducation.co.uk/privacy-policy/"
          target="_blank"
          rel="noopener noreferrer"
        >
          Privacy Policy
        </a>
        .
      </>
    ),
    component: Checkbox,
    checked: false,
  },
  subscribeToPartner: {
    label: (programName, partnerPrivacyUrl) => (
      <>
        I would like to receive information from the Abbott Fund [and Abbott
        ______] about {programName} programme, as well as other Abbott Fund
        initiatives in accordance with Abbott Fund’s{' '}
        <OutboundLink to={partnerPrivacyUrl}>Privacy Policy</OutboundLink>.
      </>
    ),
    component: Checkbox,
    checked: false,
  },
};

/*=============================================
=            Schema Spanish            =
=============================================*/

const schemaSpanish = {
  email: {
    label: 'Correo Electrónico:',
    component: TextInput,
    type: 'email',
    value: '',
    placeholder: 'email@address.com',
    required: true,
    validation: {
      test: (value) => /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(value),
      message: 'Please provide a valid email address.',
    },
  },
  inGradesKto3: {
    label: 'K–3',
    component: Checkbox,
    checked: false,
  },
  inGrades4to5: {
    label: '4–5',
    component: Checkbox,
    checked: false,
  },
  inGrades6to8: {
    label: '6–8',
    component: Checkbox,
    checked: false,
  },
  inGrades9to12: {
    label: '9–12',
    component: Checkbox,
    checked: false,
  },
  interestedInEducation: {
    label: 'Educación Física y de Salud',
    component: Checkbox,
    checked: false,
  },
  interestedInSTEM: {
    label: 'STEM',
    component: Checkbox,
    checked: false,
  },
  interestedInLanguageArts: {
    label: 'Lengua y Literatura',
    component: Checkbox,
    checked: false,
  },

  interestedInVirtualFieldTrips: {
    label: 'Viaje de Campo Virtual ',
    component: Checkbox,
    checked: false,
  },
  interestedInSweepstakesAndChallenges: {
    label: 'Concursos y Retos',
    component: Checkbox,
    checked: false,
  },
  role: {
    label: 'Función:',
    component: Select,
    placeholder: 'Select',
    options: roles,
    value: '',
    required: false,
    labelHidden: false,
  },
  month: {
    label: 'Month',
    component: Select,
    placeholder: 'Mes',
    options: months,
    value: '',
    required: true,
    labelHidden: true,
  },
  year: {
    label: 'Year',
    component: Select,
    placeholder: 'Año',
    options: years,
    value: '',
    required: true,
    labelHidden: true,
  },
  subscribeToDE: {
    label: (
      <>
        Me gustaría estar al tanto de las novedades de los servicios, novedades,
        oportunidades de formación profesional, eventos especiales, programas
        educativos y noticias de Discovery Education [y sus filiales] conforme a
        la política de privacidad de Discovery Education{' '}
        <a
          href="https://www.discoveryeducation.com/privacy-policy"
          target="_blank"
          rel="noopener noreferrer"
        >
          Privacy Policy
        </a>
        .
      </>
    ),
    component: Checkbox,
    checked: false,
  },
  subscribeToPartner: {
    label: (programName, partnerPrivacyUrl) => (
      <>
        Me gustaría recibir información de la fundación Abbott Fund [y de Abbott
        ______] sobre el programa Future Well Kids, así como de otras
        iniciativas de la fundación, conforme a la política de privacidad de
        Abbott Fund.{' '}
        <OutboundLink to={partnerPrivacyUrl}>Privacy Policy</OutboundLink>.
      </>
    ),
    component: Checkbox,
    checked: false,
  },
};

export { schemaUS, schemaIR, schemaUK, schemaSpanish };
