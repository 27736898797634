import React, { useEffect, useState, useContext, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames';
import DomainContext from '../../context/DomainContext';
import Icon from '../Icon';
import pathMap from './pathMap';
import './styles.scss';

/**
 * Component that supports switching to a different locale on the same page, even
 * if that page has a translated slug.  This relies on react-i18next to translate
 * the path.
 *
 * This component also relies on the 'DomainContext' which in turn relies on PHP to provide
 * the domains for other locales.
 *
 * If no domains are loaded, we fallback to using the query parameter '?lang=us'.
 *
 * The drop-down will NOT be available until the 'domainQueryComplete' returns true.
 * But this is only needed on the first page load (or when a user hits 'refresh'), i.e.
 * when navigating to local pages, the domain data stays in the provider context, so the
 * dropdown will work immediately after the first page load.
 */
const LanguageSwitcher = () => {
  const [open, setOpen] = useState(false);
  const [currentPath, setCurrentPath] = useState(null);
  const { i18n } = useTranslation(['paths']);
  const { domainQueryComplete, domainLookup } = useContext(DomainContext);
  const list = useRef(null);

  const getClassNames = () => {
    return classNames(
      'language-switcher',
      domainQueryComplete && open && 'language-switcher--open'
    );
  };

  const getLinkForLocale = (locale) => {
    // For the current locale, return false so the link is inactive
    if (locale === i18n.language) return undefined;

    // Return early if the domain query is not finished or we haven't finished parsing the path.
    if (!currentPath || !domainQueryComplete) return '/#';

    // Set the currentPath in another locale OR if not found, fallback to homepage
    const newPath =
      (pathMap[currentPath] && pathMap[currentPath][locale]) || '/';

    // If a domain is found for the given locale, use that in the URL
    if (domainLookup[locale]) {
      return `${window.location.protocol}//${domainLookup[locale]}${newPath}`;
    }

    // Fallback to using the current path with a 'lang' param so the backend detects the switch
    return `${newPath}?lang=${locale}`;
  };

  // Effect to set current path
  useEffect(() => {
    let path = decodeURI(window.location.pathname);
    // Add trailing slash if not there
    if (!path.substring(path.length - 1) === '/') {
      path = `${path}/`;
    }
    setCurrentPath(path);
  }, []);

  const handleClick = () => {
    if (currentPath && domainQueryComplete) {
      setOpen(!open);
    }
  };

  const getHeight = () => (open ? list.current.offsetHeight : '');

  return (
    <div
      role="button"
      tabIndex="0"
      onClick={handleClick}
      className={getClassNames()}
    >
      <div className="language-switcher__inner" style={{ height: getHeight() }}>
        <ul className="language-switcher__list" ref={list}>
          <li
            className={`language-switcher--us language-switcher__item${
              i18n.language === 'us'
                ? ' language-switcher__item--is-active'
                : ''
            }`}
          >
            <a
              className="language-switcher__link"
              href={getLinkForLocale('us')}
            >
              United States
            </a>
          </li>
          <li
            className={`language-switcher--mx language-switcher__item${
              i18n.language === 'mx'
                ? ' language-switcher__item--is-active'
                : ''
            }`}
          >
            <a
              className="language-switcher__link"
              href={getLinkForLocale('mx')}
            >
              Mexico
            </a>
          </li>
          <li
            className={`language-switcher--ir language-switcher__item${
              i18n.language === 'ir'
                ? ' language-switcher__item--is-active'
                : ''
            }`}
          >
            <a
              className="language-switcher__link"
              href={getLinkForLocale('ir')}
            >
              Ireland
            </a>
          </li>
          <li
            className={`language-switcher--co language-switcher__item${
              i18n.language === 'co'
                ? ' language-switcher__item--is-active'
                : ''
            }`}
          >
            <a
              className="language-switcher__link"
              href={getLinkForLocale('co')}
            >
              Colombia
            </a>
          </li>
          <li
            className={`language-switcher--uk language-switcher__item${
              i18n.language === 'uk'
                ? ' language-switcher__item--is-active'
                : ''
            }`}
          >
            <a
              className="language-switcher__link"
              href={getLinkForLocale('uk')}
            >
              United Kingdom
            </a>
          </li>
          <li
            className={`language-switcher--in language-switcher__item${
              i18n.language === 'in'
                ? ' language-switcher__item--is-active'
                : ''
            }`}
          >
            <a
              className="language-switcher__link"
              href={getLinkForLocale('in')}
            >
              India
            </a>
          </li>
        </ul>
        <Icon
          name="caratdown"
          className={classNames(
            'language-switcher__arrow',
            open && 'language-switcher__arrow--rotated'
          )}
        />
      </div>
    </div>
  );
};

export default LanguageSwitcher;
