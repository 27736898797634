export const months = {
  January: 'January',
  February: 'February',
  March: 'March',
  April: 'April',
  May: 'May',
  June: 'June',
  July: 'July',
  August: 'August',
  September: 'September',
  October: 'October',
  November: 'November',
  December: 'December',
};

const generateDayOptions = () => {
  const dayNumbers = {};
  for (let i = 1; i <= 31; i++) {
    dayNumbers[i] = i.toString();
  }
  return dayNumbers;
};

export const days = generateDayOptions();

const generateYearOptions = () => {
  const yearNumbers = {};
  const startYear = 1900;
  const endYear = new Date().getFullYear();

  for (let i = endYear; i >= startYear; i--) {
    yearNumbers[i] = i.toString();
  }

  const keys = Object.keys(yearNumbers);
  keys.sort(function (a, b) {
    return b - a;
  });
  const yearNewObj = {};

  for (let i = 0; i < keys.length; i++) {
    yearNewObj[i] = yearNumbers[keys[i]];
  }

  return yearNewObj;
};

export const years = generateYearOptions();
