import React, { useState, useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { useStaticQuery, graphql, Link } from 'gatsby';
import { useLocation, globalHistory } from '@reach/router';
import classNames from 'classnames';
import { Container } from '../Grid';
import Share from '../Share';
import Subscribe from '../Subscribe';
import Button from '../Button';
import Image from '../Image';
import Icon from '../Icon';
import LanguageSwitcher from '../LanguageSwitcher';
import './styles.scss';

/**
 * A global header component that is populated with properties from `gatsby-config.js`
 */

const Header = () => {
  const [isMobileMenuVisible, setIsMobileMenuVisible] = useState(false);
  const [activeSubMenu, setActiveSubMenu] = useState(null);
  const [isSticky, setIsSticky] = useState(false);
  const [isOffPage, setIsOffPage] = useState(false);
  const [viewportWidth, setViewportWidth] = useState(
    typeof window !== `undefined` ? window.innerWidth : null
  );
  const { t } = useTranslation(['paths']);

  const header = useRef(null);
  const headerUpper = useRef(null);
  const headerLower = useRef(null);
  const logo = useRef(null);
  const partnerLogos = useRef(null);
  const menu = useRef(null);

  const { pathname: currentPath } = useLocation();

  const isHome = currentPath === '/';

  const { site } = useStaticQuery(graphql`
    query {
      site {
        siteMetadata {
          partnerName
          partnerLogo
          programName
          programLogo
          menuLinks {
            labelId
            path
            children {
              labelId
              path
            }
          }
        }
      }
    }
  `);

  useEffect(() => {
    const threshold = 80;
    let ticking = false;
    let lastScrollY = window.pageYOffset;

    const mobileMenuHeight = isMobileMenuVisible
      ? menu.current.offsetHeight
      : 0;
    const headerHeight = header.current.offsetHeight + mobileMenuHeight;
    const headerUpperStyles = window.getComputedStyle(header.current);
    const headerUpperPaddingTop = parseInt(
      headerUpperStyles.getPropertyValue('padding-top'),
      10
    );

    const stickyHeaderOffset =
      headerUpper.current.offsetHeight + headerUpperPaddingTop;

    if (isSticky) {
      header.current.style.transform = `translateY(-${stickyHeaderOffset}px)`;
    } else {
      header.current.style.transform = '';
    }

    const toggleHeader = () => {
      const scrollY = window.pageYOffset;

      if (scrollY > headerHeight) {
        if (!isOffPage) {
          setIsOffPage(true);
          setActiveSubMenu(null);
        }
        if (isMobileMenuVisible) setIsMobileMenuVisible(false);
      }

      if (
        scrollY > headerHeight &&
        Math.abs(scrollY - lastScrollY) < threshold
      ) {
        ticking = false;
        return;
      }

      if (
        (scrollY <= stickyHeaderOffset || scrollY > lastScrollY) &&
        isSticky
      ) {
        setIsSticky(false);
        if (scrollY <= stickyHeaderOffset) {
          if (isOffPage) setIsOffPage(false);
        }
      } else if (
        scrollY > stickyHeaderOffset &&
        scrollY < lastScrollY &&
        !isSticky
      ) {
        if (isMobileMenuVisible) setIsMobileMenuVisible(false);
        setIsSticky(true);
      }

      lastScrollY = scrollY > 0 ? scrollY : 0;
      ticking = false;
    };

    const handleScroll = () => {
      if (!ticking) {
        window.requestAnimationFrame(toggleHeader);
        ticking = true;
      }
    };

    const handleResize = () => {
      if (typeof window !== `undefined`) {
        setViewportWidth(window.innerWidth);

        if (window.innerWidth >= 1200 && isMobileMenuVisible) {
          setIsMobileMenuVisible(false);
        }
      }
    };

    window.addEventListener('scroll', handleScroll);
    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('scroll', handleScroll);
      window.removeEventListener('resize', handleResize);
      globalHistory.listen(({ action }) => {
        if (action === 'PUSH') {
          setIsOffPage(false);
          setIsSticky(false);
          setIsMobileMenuVisible(false);
        }
      });
    };
  }, [isSticky, isOffPage, isMobileMenuVisible, viewportWidth]);

  const handleHamburgerClick = () => {
    setIsMobileMenuVisible(!isMobileMenuVisible);
  };

  const handleSubmenuToggle = (e, path) => {
    e.preventDefault();
    setActiveSubMenu((currentPath) => (currentPath === path ? null : path));
  };

  const renderPartnerLogos = (scaled, hidden) => (
    <div
      className={classNames(
        'header__partner-logos',
        (hidden || (!isHome && isSticky)) && 'header__partner-logos--hidden'
      )}
      ref={partnerLogos}
    >
      <div
        className={classNames(
          'header__partner-logo',
          scaled && 'header__partner-logo--scaled'
        )}
      >
        <Image
          filename={site.siteMetadata.partnerLogo}
          alt={site.siteMetadata.partnerName}
        />
      </div>
      <div className="header__logo-divider" />
      <div
        className={classNames(
          'header__de-logo',
          scaled && 'header__de-logo--scaled'
        )}
      >
        <Image filename="de-logo.png" alt="Discovery Education" />
      </div>
    </div>
  );

  const renderUtilityMenu = () => (
    <div className="header__utility-menu">
      <div className="header__signup">
        <Subscribe
          trigger={
            <Button className="header__signup-button" link>
              Updates <Icon name="signup" marginLeft />
            </Button>
          }
        />
      </div>
      <div className="header__share">
        <Share inDropDown />
      </div>
    </div>
  );

  const renderMainMenu = (links, parent = null) => {
    return (
      <ul
        className={classNames(
          parent ? 'header__main-menu-sublist' : 'header__main-menu-list',
          parent &&
            activeSubMenu === parent &&
            'header__main-menu-sublist--open'
        )}
      >
        {links.map((link, i) => {
          return (
            typeof t(link.path) === 'object' && (
              <li
                key={i}
                className={classNames(
                  parent
                    ? 'header__main-menu-sublist-item'
                    : 'header__main-menu-list-item',
                  activeSubMenu === link.path &&
                    (parent
                      ? 'header__main-menu-sublist-item--active'
                      : 'header__main-menu-list-item--active')
                )}
              >
                <Link
                  activeClassName="header__main-menu-link--active"
                  to={t(link.path).path}
                  onClick={
                    link.children && ((e) => handleSubmenuToggle(e, link.path))
                  }
                  className={
                    parent
                      ? 'header__main-menu-sublist-link'
                      : 'header__main-menu-link'
                  }
                >
                  {link.labelId ? t(link.labelId) : t(link.path).label}{' '}
                  {link.children && (
                    <Icon
                      name="caratdown"
                      className={classNames(
                        'header__main-menu-carat',
                        activeSubMenu === link.path &&
                          'header__main-menu-carat--rotated'
                      )}
                      marginLeft
                    />
                  )}
                </Link>
                {link.children && renderMainMenu(link.children, link.path)}
              </li>
            )
          );
        })}
      </ul>
    );
  };

  return (
    <header
      className={classNames('header', isOffPage && 'header--off-page')}
      ref={header}
    >
      <div
        className={classNames('header__upper', isHome && 'header__upper--home')}
        ref={headerUpper}
      >
        <Container fullWidth>
          <div className="header__upper-content">
            {renderPartnerLogos(true, isHome)}
            {renderUtilityMenu()}
          </div>
        </Container>
      </div>
      <div className="header__lower" ref={headerLower}>
        <Container fullWidth>
          <div className="header__lower-content">
            {isHome ? (
              renderPartnerLogos(false)
            ) : (
              <div className="header__program-logo" ref={logo}>
                <Link to="/">
                  <Image
                    filename={site.siteMetadata.programLogo}
                    alt={site.siteMetadata.programName}
                  />
                </Link>
              </div>
            )}
            <div className="header__hamburger">
              <Button
                link
                className={classNames(
                  'header__hamburger-button',
                  isMobileMenuVisible && 'header__hamburger-button--active',
                  isSticky && 'header__hamburger-button--in-sticky'
                )}
                onClick={handleHamburgerClick}
              >
                <div className="header__hamburger-button-inner"></div>
              </Button>
            </div>
            <div
              className={classNames(
                'header__collapse',
                isMobileMenuVisible && 'header__collapse--visible'
              )}
              ref={menu}
            >
              <nav className="header__main-menu">
                {renderMainMenu(site.siteMetadata.menuLinks)}
              </nav>
              <div className="header__language-switcher">
                <LanguageSwitcher />
              </div>
              {isMobileMenuVisible && renderUtilityMenu()}
            </div>
          </div>
        </Container>
      </div>
    </header>
  );
};

export default Header;
